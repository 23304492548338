<template>
  <div class="d-flex flex-column mr-3">
    <DashboardFilters
      :auto-refresh="autoRefresh"
      :toggle-auto-refresh="TOGGLE_AUTO_REFRESH"
      :refresh="reloadData"
      :date-range="dateRange"
      :time-zone="timeZone"
      :max-date-range-in-days="90"
      @date-range-updated="handleDateRangeUpdate"
      @update-time-zone="handleTimeZoneUpdate"
    />

    <div
      class="row mt-4"
      style="margin: 0 10px;"
    >
      <div class="col-md-4 col-xl-2 col-lg-3 pl-0">
        <ReportingCardStats
          :icon="['fas', 'phone-volume']"
          :stats-number="totalItems"
          stats-title="Total Calls"
          icon-color="var(--neon-green-color)"
        />
      </div>

      <div class="col-md-4 col-xl-2 col-lg-3">
        <ReportingCardStats
          :icon="['fas', 'money-check-alt']"
          :stats-number="totalCost"
          is-stats-number-currency
          stats-title="Bought for"
          icon-color="var(--stats-blue-color)"
        />
      </div>
    </div>

    <Panel size="small">
      <template v-slot:header>
        <span data-cy="heading">Call Logs</span>
      </template>

      <template v-slot:content>
        <b-row>
          <b-table
            ref="callLogsTable"
            :fields="fields"
            data-cy="table-buyer-call-logs"
            :items="loadBuyerCallLogs"
            :busy.sync="isLoading"
            thead-class="text-nowrap"
            tbody-tr-class="text-nowrap"
            responsive
            :per-page="pageSize"
            :current-page="currentPage"
            caption-top
            sort-desc
            sort-by="timestamp"
            class="col-12"
          >
            <template #table-caption>
              <div class="ml-2">
                <BaseButtonLoader
                  v-if="totalItems > 0"
                  size="sm"                          
                  nowrap                  
                  :loading="isExportLoading"
                  variant="secondary"
                  @click="() => exportToFile()"
                >
                  Export CSV
                </BaseButtonLoader>
              </div>
            </template>

            <template #cell(timestamp)="row">
              <div class="text-nowrap">
                {{ dateFormat(row.item.timestamp, 'MMM DD hh:mm:ss A') }}
              </div>
            </template>

            <template #cell(country)="row">
              <div class="text-nowrap">
                {{ row.item.country }}-{{ row.item.language }}
              </div>
            </template>

            <template #cell(trafficSourceName)="row">
              <div class="text-nowrap">
                {{ row.item.trafficSourceName }} <small class="text-muted">{{ row.item.trafficSourceType }}</small>
              </div>
            </template>

            <template #cell(channel)="row">              
              <div>
                <button
                  v-if="row.item.channel !== null && row.item.channel !== undefined"
                  class="btn btn-outline-info btn-sm"
                  @click="showChannelDetailsModal(row.item)"                  
                >
                  <strong>{{ row.item.channel }}</strong>
                </button>
              </div>              
            </template>

            <template #cell(callId)="row">
              <div class="d-flex align-items-center">
                <div
                  class="text-truncate fixed-width-150 mr-2"
                  :title="row.item.callId"
                >
                  {{ row.item.callId }}
                </div>
                
                <font-awesome-icon
                  role="button"
                  :icon="['fas', 'copy']"
                  @click="copyCallIdToClipboard(row.item.callId)"
                />
              </div>               
            </template>

            <template #cell(durationInSeconds)="row">
              <div class="text-nowrap">
                {{ secondsToDuration(row.item.durationInSeconds) }}
              </div>
            </template>

            <template #cell(conversionRequiredCallDuration)="row">
              <div class="text-nowrap">
                {{ secondsToDuration(row.item.conversionRequiredCallDuration) }}
              </div>
            </template>

            <template #cell(cost)="row">
              <div class="text-nowrap">
                {{ formatCurrency(row.item.cost) }}
              </div>
            </template>

            <template #cell(recordingUrl)="row">              
              <div>
                <button
                  v-if="row.item.recordingUrl !== null && row.item.recordingUrl !== undefined"
                  class="btn btn-outline-info btn-sm"
                  @click="openRecording(row.item.recordingUrl)"                  
                >
                  Play
                </button>                
              </div>
            </template>
          </b-table>
        </b-row>
        
        <div class="d-flex justify-content-center">
          <b-pagination
            v-model="currentPage"
            size="sm"
            :total-rows="totalItems"
            :per-page="pageSize"
          />
        </div>
      </template>
    </Panel>

    <b-modal
      id="channel-details-modal"
      ref="channel-details-modal"
      header-class="custom-modal"
      body-class="custom-modal"
      footer-class="custom-modal"
      title="Channel details"
      ok-title="Channel details"
      button-size="sm"
      @ok="showBlockChannelModal"
    >
      <p
        v-if="selectedCall"
        class="mb-2"
      >
        Channel <strong>{{ selectedCall.channel }}</strong>
      </p>

      <template #modal-footer>
        <b-button
          type="button"
          variant="secondary"
          size="sm"
          :disabled="isLoading"
          @click="$bvModal.hide('channel-details-modal')"
        >
          Close
        </b-button>

        <BaseButtonLoader
          :loading="isLoading"
          class="ml-2"
          type="button"
          :custom-button="false"
          size="sm"
          variant="danger"
          @click="showBlockChannelModal(selectedCall)"
        >
          Block channel
        </BaseButtonLoader>
      </template>
    </b-modal>

    <b-modal
      id="confirm-block-channel-modal"
      ref="confirm-block-channel-modal"
      header-class="custom-modal"
      body-class="custom-modal"
      footer-class="custom-modal"
      title="Confirm Block Channel"
      ok-title="Block Channel"
      button-size="sm"
      @ok="confirmBlockChannel"
    >
      <p
        v-if="selectedCall"
        class="mb-2"
      >
        Are you sure you want to block <strong>{{ selectedCall.channel }}</strong> channel?
      </p>

      <template #modal-footer>
        <b-button
          type="button"
          variant="secondary"
          size="sm"
          :disabled="isLoading"
          @click="$bvModal.hide('confirm-block-channel-modal')"
        >
          Cancel
        </b-button>

        <BaseButtonLoader
          :loading="isLoading"
          class="ml-2"
          type="button"
          :custom-button="false"
          size="sm"
          variant="danger"
          @click="confirmBlockChannel"
        >
          Block
        </BaseButtonLoader>
      </template>
    </b-modal>
  </div>
</template>

<script>

import DashboardFilters from '@/components/DashboardFilters.vue'
import dateFormatMixin from '@/mixins/dateFormatMixin'
import callLogsMixin from '@/mixins/call-logs.mixin'
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
import { showSuccessMessage, showErrorMessage } from '@/notification-utils'
import BuyerCallLogsAPI from '@/api/buyer-call-logs.api'
import formatCurrency from '@/utils/formatCurrency'
import ReportingCardStats from '@/components/common/ReportingCardStats.vue'

export default {
  name: 'BuyerCallLogs',

  title: 'Call Logs',

  components: {
    DashboardFilters,
    ReportingCardStats
  },

  mixins: [dateFormatMixin, callLogsMixin, copyToClipboardMixin],

  data() {
    return {
      fields: Object.freeze([
        { key: 'timestamp', label: 'Date', sortable: true, sortDirection: 'desc' },
        { key: 'buyerCampaignName', label: 'Campaign' },
        { key: 'categoryName', label: 'Category' },
        { key: 'trafficSourceName', label: 'Traffic Source' },
        { key: 'country', label: 'Locale' },
        { key: 'channel', label: 'Channel' },
        { key: 'callerId', label: 'Caller ID' },
        { key: 'durationInSeconds', label: 'Duration' },
        { key: 'conversionRequiredCallDuration', label: 'Min Duration' },
        { key: 'cost', label: 'Cost' },
        { key: 'recordingUrl', label: 'Recording' },
        { key: 'callId', label: 'Call ID' },
        { key: 'cmsId', label: 'SMID (CMS ID)' },
      ]),
      totalCost: 0,
      selectedCall: null
    }
  },
  
  methods: {
    formatCurrency,

    async loadBuyerCallLogs(ctx) { // eslint-disable-line
      try {
        this.isLoading = true

        if (this.totalItems === 0) {
          await this.getTotalCalls()
        }

        const response = await BuyerCallLogsAPI.getBuyerCallLogs(this.accountId, {
          from: this.dateRange[0],
          to: this.dateRange[1],
          timeZone: this.timeZone,
          pageSize: ctx.perPage,
          pageNumber: ctx.currentPage
        })

        this.calls = response.data        

        return response.data
      } catch (error) {
        if (error.response?.status === 500) {
          showErrorMessage(`Unable to load call logs`)
        } else {
          showErrorMessage(error.response?.data?.title || `Unable to load call logs`)
        }

        this.calls = []

        return []
      } finally {
        this.isLoading = false
      }
    },

    async getTotalCalls() {
      const totalCallsresponse = await BuyerCallLogsAPI.getBuyerTotalCalls(this.accountId, {
        from: this.dateRange[0],
        to: this.dateRange[1],
        timeZone: this.timeZone
      })

      this.totalItems = totalCallsresponse.data.totalCallCount
      this.totalCost = totalCallsresponse.data.totalCost
    },

    openRecording(url) {
      window.open(url)
    },

    async exportToFile() {
      try {
        this.isExportLoading = true

        const exportResponse = await BuyerCallLogsAPI.exportBuyerCalls(this.accountId, {
          from: this.dateRange[0],
          to: this.dateRange[1],
          timeZone: this.timeZone
        })

        window.location.href = exportResponse.data.url
      }
      catch (error) {
        if (error.response.status === 500) {
          showErrorMessage(`Unable to export calls to file`)
        }
        else {
          showErrorMessage(error.response.data.title)
        }        
      }
      finally {
        this.isExportLoading = false      
      }
    },

    showBlockChannelModal(call) {
      this.selectedCall = call

      this.$bvModal.show('confirm-block-channel-modal')
    },   
    
    showChannelDetailsModal(call) {
      this.selectedCall = call

      this.$bvModal.show('channel-details-modal')
    },

    async confirmBlockChannel() {
      try {
        this.isLoading = true

        await BuyerCallLogsAPI.blockChannel(this.accountId, {
          callId: this.selectedCall.callId,
          channel: this.selectedCall.channel
        })

        showSuccessMessage('Successfully blocked channel ')

        this.selectedCall = null
        this.$bvModal.hide('confirm-block-channel-modal')
        this.$bvModal.hide('channel-details-modal')
      }
      catch (error) {
        if (error.response.status === 500) {
          showErrorMessage(`Unable to block a channel`)
        }
        else {
          showErrorMessage(error.response.data.title)
        }        
      }
      finally {
        this.isLoading = false
        
        this.$bvModal.hide('confirm-block-channel-modal')
        this.$bvModal.hide('channel-details-modal')
      }
    },

    copyCallIdToClipboard(id) {
      this.copyTextToClipboard(id)
      showSuccessMessage('Call ID copied to clipboard')
    }

  }
}
</script>

<style lang="scss" scoped>

</style>